<template>
  <div id="layout-default" class="container-fluid p-0">
    <nav
      class="
        navbar navbar-expand-md navbar-dark
        shadow-none
        bg-transparent
        flex-nowrap
      "
    >
      <router-link
        class="
          navbar-brand
          group-logo
          py-1
          px-4
          ml-3
          mr-0
          bg-default-dark
          rounded-pill
          border border-dark
          shadow-sm
        "
        :to="{ name: 'landing' }"
      >
        <img
          v-if="user && user.company && user.company.logo"
          :src="Helper.formatMediaUrl(user.company.logo)"
          :alt="user.company"
        />
        <img
          v-else-if="domain && domain.includes('selgem')"
          src="@/assets/images/selgem-logo.png"
          class=""
          alt="Selgem"
        />
        <img
          v-else
          src="@/assets/images/infrasel-logo-white.png"
          class=""
          alt="InfraSEL"
        />
        <!-- <p class="mb-0 mt-1 ml-2 text-muted">Disaster Monitoring System</p> -->
      </router-link>
      <button
        class="
          navbar-toggler
          ml-2
          h-50
          py-0
          bg-default-dark
          rounded-pill
          shadow-sm
        "
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars small" />
      </button>
      <div
        class="
          collapse
          navbar-collapse
          bg-transparent
          shadow-none
          border-0
          flex-grow-1
        "
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav flex-lg-fill py-2 pl-3 pr-0">
          <li
            class="nav-item py-2 py-md-0"
            v-for="link in navLinks"
            :key="`navLink-${link.name}`"
            :class="{ 'd-none': !link.show }"
          >
            <router-link
              :to="{ name: link.name }"
              class="
                nav-link
                bg-default-dark
                rounded-pill
                border border-dark
                shadow-sm
                px-4
              "
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
            >
              {{ link.label }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="mr-3 ml-auto d-flex justify-content-end align-items-center">
        <router-link
          class="btn btn-primary btn-sm px-3 rounded-pill mr-2 text-nowrap"
          v-for="link in additionalLinks"
          :key="link.name"
          :to="{ name: link.name }"
        >
          <i :class="`${link.icon} mr-2`" v-if="link.icon" />
          {{ link.label }}
        </router-link>
        <site-dropdown class="d-none d-sm-flex mr-2" />
        <user-dropdown />
      </div>
    </nav>
    <slot />
    <footer class="row mx-0 border-top border-dark bg-dark">
      <div class="col d-flex justify-content-start align-items-center">
        <img
          src="@/assets/images/powered-by-selgem.png"
          class="footer-logo"
          alt="Powered by SELGEM"
        />
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <p class="mb-0 small">&copy; SELGEM {{ new Date().getFullYear() }}</p>
      </div>
    </footer>
  </div>
</template>

<script>
import SiteDropdown from "@/components/SiteDropdown";
import UserDropdown from "@/components/UserDropdown";

export default {
  name: "layout-default",
  components: {
    SiteDropdown,
    UserDropdown,
  },
  data() {
    return {
      domain: location.host,
      user: "",
      navLinks: [
        {
          name: "disaster.dashboard",
          label: "Dashboard",
          show: true,
        },
        {
          name: "disaster.map",
          label: "Map",
          show: true,
        },
        {
          name: "disaster.alert",
          label: "Alert",
          show: true,
        },
        {
          name: "disaster.cctv",
          label: "CCTV",
          show: true,
        },
      ],
      additionalLinks: [
        {
          name: "disaster.live-stream",
          icon: "fas fa-video",
          label: "LIVE",
          show: true,
        },
      ],
    };
  },
  mounted() {
    const user = localStorage.getItem("user");

    this.user = user ? JSON.parse(user) : "";

    if (
      this.user &&
      this.user.company &&
      this.user.company.hidden_menu &&
      this.user.company.hidden_menu.length > 0
    ) {
      this.user.company.hidden_menu.forEach((menu) => {
        const navLink = this.navLinks.find((nl) => nl.name == menu);

        if (navLink) navLink.show = false;
      });
    }
  },
};
</script>