<template>
  <div class="user-dropdown text-right">
    <button
      type="button"
      class="btn dropdown-toggle no-focus no-pseudo"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img src="@/assets/icons/user-blue.png" class="icon-sm" alt="User Icon" />
    </button>
    <div class="dropdown-menu dropdown-menu-right border border-dark">
      <div class="dropdown-item-text text-default-dark">{{ user.email }}</div>
      <div class="dropdown-divider border-dark"></div>
      <template
        v-if="
          user &&
          user.isAdmin &&
          $route.name &&
          $route.name.includes('disaster.')
        "
      >
        <router-link class="dropdown-item" :to="{ name: 'disaster.add-site' }">
          <i class="fas fa-plus-square mr-2" />
          Create new site
        </router-link>
        <div class="dropdown-divider border-dark"></div>
      </template>
      <button class="dropdown-item" @click="logout()">
        <i class="fas fa-sign-out-alt mr-2" />
        Logout
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-dropdown",
  data() {
    return {
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>