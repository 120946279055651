<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      defaultLayout: "default",
    };
  },
  computed: {
    layout() {
      let layout = `${this.$route.meta.layout || this.defaultLayout}-layout`;
      return layout;
    },
  },
  async mounted() {
    const token = localStorage.getItem("token");

    if (token) {
      // Test token
      const [getCall, getCallErr] = await this.Helper.handle(
        this.API.get("companies?_limit=1")
      );

      if (getCallErr) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");

        this.$router.push({
          name: "login",
        });
      }
    }
  },
};
</script>