<template>
  <div id="layout-dashboard">
    <div id="sidebar" :class="{ hide: hideSidebar }">
      <div class="sidebar-header">
        <router-link class="navbar-brand" :to="{ name: 'landing' }">
          <img
            v-if="user && user.company && user.company.logo"
            :src="Helper.formatMediaUrl(user.company.logo)"
            :alt="user.company"
          />
          <img
            v-else-if="domain && domain.includes('selgem')"
            src="@/assets/images/selgem-logo.png"
            alt="Selgem"
          />
          <img
            v-else
            src="@/assets/images/infrasel-logo-white.png"
            alt="InfraSEL"
          />
        </router-link>
        <button
          id="sidebar-toggler"
          class="btn btn-sm btn-link text-light"
          @click="toggleSidebar()"
        >
          <i class="fas fa-angle-double-left fa-2x align-middle" />
        </button>
      </div>
      <div class="sidebar-content">
        <div class="accordion" id="sidebar-links">
          <div
            class="card"
            v-for="parent in sidebarLinks"
            :key="`sidebar-link-parent-${parent.name}`"
          >
            <button
              class="btn btn-link btn-block text-nowrap parent"
              type="button"
              data-toggle="collapse"
              :data-target="`#sidebar-collapse-${parent.name}`"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <i :class="parent.icon" class="nav-icon" />
              <span>{{ parent.label }}</span>
            </button>

            <div
              :ref="`sidebar-collapse-${parent.name}`"
              :id="`sidebar-collapse-${parent.name}`"
              class="collapse"
              data-parent="#sidebar-links"
            >
              <div class="card-body pt-2 pb-0 px-2 text-nowrap">
                <button
                  v-for="child in parent.childrens"
                  :key="`sidebar-link-child-${child.name}`"
                  :disabled="child.disabled"
                  class="btn btn-link btn-block text-nowrap py-2 mb-2"
                  :class="{ active: $route.name.includes(`${child.name}.`) }"
                  @click="goTo(child.to)"
                >
                  <i :class="child.icon" class="nav-icon mr-3" />
                  <span>{{ child.label }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-footer">
        <img
          src="@/assets/images/powered-by-selgem.png"
          class="footer-logo"
          alt="Powered by SELGEM"
        />
        <p class="mb-0 small text-nowrap">
          &copy; SELGEM {{ new Date().getFullYear() }}
        </p>
      </div>
    </div>
    <div class="main-content">
      <nav class="navbar navbar-expand">
        <template v-if="hideSidebar">
          <button
            id="sidebar-toggler"
            class="btn btn-dark border-secondary"
            @click="toggleSidebar()"
          >
            <i class="fas fa-bars" />
          </button>
          <router-link class="navbar-brand" :to="{ name: 'landing' }">
            <img
              v-if="user && user.company && user.company.logo"
              :src="Helper.formatMediaUrl(user.company.logo)"
              :alt="user.company"
            />
            <img
              v-else-if="domain && domain.includes('selgem')"
              src="@/assets/images/selgem-logo.png"
              alt="Selgem"
            />
            <img
              v-else
              src="@/assets/images/infrasel-logo-white.png"
              alt="InfraSEL"
            />
          </router-link>
        </template>
        <template v-if="$route.name.includes('disaster')">
          <div class="dropdown ml-auto d-block d-lg-none">
            <button
              class="btn btn-primary rounded-pill pl-4 pr-3 dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fas fa-bars mr-2" />
              Disaster
            </button>
            <div class="dropdown-menu">
              <router-link
                :to="{ name: link.name }"
                class="dropdown-item py-3"
                v-for="link in navLinks"
                :key="`mobile-navLink-${link.name}`"
              >
                {{ link.label }}
              </router-link>
            </div>
          </div>
          <ul class="navbar-nav ml-auto d-none d-lg-flex">
            <li
              class="nav-item py-2 py-md-0"
              v-for="link in navLinks"
              :key="`navLink-${link.name}`"
              :class="{ 'd-none': !link.show }"
            >
              <router-link
                :to="{ name: link.name }"
                class="nav-link bg-default-dark rounded-pill border border-secondary px-4"
              >
                {{ link.label }}
              </router-link>
            </li>
            <li
              class="nav-item"
              v-for="link in additionalLinks"
              :key="link.name"
            >
              <router-link
                class="btn btn-primary btn-sm px-3 rounded-pill text-nowrap"
                :to="{ name: link.name }"
              >
                <i :class="`${link.icon} mr-2`" v-if="link.icon" />
                {{ link.label }}
              </router-link>
            </li>
            <li class="nav-item d-none d-sm-flex">
              <site-dropdown />
            </li>
          </ul>
        </template>
        <ul
          class="navbar-nav"
          :class="{ 'ml-auto': !$route.name.includes('disaster') }"
        >
          <li class="nav-item">
            <user-dropdown />
          </li>
        </ul>
      </nav>
      <div class="router-view">
        <router-view :hide-sidebar="hideSidebar"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import SiteDropdown from "@/components/SiteDropdown";
import UserDropdown from "@/components/UserDropdown";

export default {
  name: "layout-default",
  components: {
    SiteDropdown,
    UserDropdown,
  },
  data() {
    return {
      domain: location.host,
      user: "",
      sidebarLinks: [
        {
          name: "getReady",
          label: "Get Ready",
          icon: "far fa-clipboard",
          childrens: [
            {
              name: "landSubsidence",
              label: "Land Subsidence",
              icon: "fas fa-mountain",
              disabled: true,
            },
          ],
        },
        {
          name: "dashboard",
          label: "Dashboard",
          icon: "fas fa-tachometer-alt",
          childrens: [
            {
              name: "overview",
              label: "Overview",
              icon: "far fa-newspaper",
              disabled: true,
            },
            {
              name: "disaster",
              label: "Disaster",
              icon: "fas fa-exclamation-triangle",
              to: "disaster.dashboard",
            },
            {
              name: "floodIncidents",
              label: "Flood Incidents",
              icon: "fas fa-water",
              disabled: true,
            },
          ],
        },
        {
          name: "recovery",
          label: "Recovery",
          icon: "fas fa-shield-alt",
          childrens: [
            {
              name: "comingSoon",
              label: "Coming Soon",
              icon: "fas fa-arrow-right",
              disabled: true,
            },
          ],
        },
      ],
      navLinks: [
        {
          name: "disaster.dashboard",
          label: "Dashboard",
          show: true,
        },
        {
          name: "disaster.map",
          label: "Map",
          show: true,
        },
        {
          name: "disaster.alert",
          label: "Alert",
          show: true,
        },
        {
          name: "disaster.cctv",
          label: "CCTV",
          show: true,
        },
      ],
      additionalLinks: [
        {
          name: "disaster.live-stream",
          icon: "fas fa-video",
          label: "LIVE",
          show: true,
        },
      ],
      hideSidebar: false,
    };
  },
  watch: {
    "$route.name": {
      handler() {
        this.handleActiveSidebar();
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    goTo(name) {
      this.$router.push({ name: name }).catch(() => {});
    },
    toggleSidebar() {
      this.hideSidebar = !this.hideSidebar;
    },
    handleActiveSidebar() {
      const routeGroup = this.$route.name?.split(".")[0];

      const allElementsKey = Object.keys(this.$refs).filter((ref) =>
        ref.includes("sidebar-collapse-")
      );

      const parent = this.sidebarLinks.find((parent) =>
        parent.childrens?.find((child) => child.name == routeGroup)
      );

      let element;

      if (parent) {
        element = `sidebar-collapse-${parent.name}`;
      } else {
        element = allElementsKey[0];
      }

      allElementsKey.forEach((key) => {
        if (key == element) {
          this.$refs[key][0].classList.add("show");
        } else {
          this.$refs[key][0].classList.remove("show");
        }
      });
    },
  },
  mounted() {
    this.handleActiveSidebar();

    const user = localStorage.getItem("user");

    this.user = user ? JSON.parse(user) : "";

    if (
      this.user &&
      this.user.company &&
      this.user.company.hidden_menu &&
      this.user.company.hidden_menu.length > 0
    ) {
      this.user.company.hidden_menu.forEach((menu) => {
        const navLink = this.navLinks.find((nl) => nl.name == menu);

        if (navLink) navLink.show = false;
      });
    }

    if (window.innerWidth < 768) {
      this.hideSidebar = true;
    }
  },
};
</script>